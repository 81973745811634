/* You can add global styles to this file, and also import other style files */

@font-face {
    font-family: "Playfair";
    src: local("Playfair"), url(./assets/Fonts/PlayfairDisplay-Font.ttf) format("truetype");
}

@font-face {
    font-family: "TitilliumWeb";
    src: local("TitilliumWeb"), url(./assets/Fonts/TitilliumWeb-Regular.ttf) format("truetype");
}

body {
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: 100vh;
    background-color: #181818;
    color: #848484;
    font-family: 'TitilliumWeb', serif;
}

*:focus {
    outline: none !important;
    border: 0 !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
}

.contactContainer input, .catalogContainer input, .contactContainer textarea {
    background-color: rgb(50, 50, 50) !important;
    border: none !important;
    color: white !important;
}

.margin-0 {
    margin: 0px !important;
}

.padding-0 {
    padding: 0px !important;
}

.pointer {
    cursor: pointer;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: rgb(255, 255, 255, 0.08);
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.5);
    background-color: #CAA462;
}

.p-grid {
    margin: 0px !important;
}

.homepage .p-button, .contactContainer .p-button, .LoginContainer .p-button {
    background: rgba(255, 255, 255, 0.1) !important;
    border: 1px solid #CAA462 !important;
    color: #CAA462 !important;
    border-radius: 0px !important;
}

.mainContainer .p-button {
    background: #20262e !important;
    border: none !important;
    padding-top: 4px;
    padding-bottom: 4px;
    color: rgba(255, 255, 255, 0.6) !important;
}

.homepage .p-button:hover, .contactContainer .p-button:hover, .LoginContainer .p-button:hover {
    background: #CAA462 !important;
    border: 1px solid #CAA462 !important;
    color: rgb(255, 255, 255) !important;
}

.homepage .p-button-secondary:hover, .contactContainer .p-button:hover, .LoginContainer .p-button:hover {
    background: rgb(0, 0, 0) !important;
    border: 1px solid #CAA462 !important;
    color: #CAA462 !important;
}

.mainContainer .inputLabel {
    margin-bottom: 10px;
}

body .mainContainer input {
    padding: 4px 8px;
}

body .mainContainer span.p-dropdown-label.p-inputtext.p-placeholder {
    padding-left: 8px;
    padding-right: 8px;
}

body .mainContainer span.p-dropdown-label.p-inputtext {
    padding-top: 4px;
    padding-bottom: 4px;
}

body .mainContainer .p-inputswitch.p-inputswitch-checked:not(.p-disabled) .p-inputswitch-slider {
    background: #CAA462;
}

body .mainContainer .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    background: rgba(255, 255, 255, 0.6);
}

body .mainContainer .p-datatable .p-datatable-thead>tr>th, body .mainContainer .p-datatable .p-datatable-scrollable-header {
    background: none;
    border: none;
}

body .mainContainer .p-datatable .p-datatable-tbody>tr>td {
    border: none;
}

body .mainContainer .p-datatable .p-datatable-tbody>tr {
    background: #191d28;
}

body .mainContainer .p-datatable .p-datatable-tbody>tr.p-highlight {
    background: #30343f;
    color: #ffffffde;
}

body .mainContainer .p-dialog.p-component {
    width: 50vw;
    border-radius: 0px;
    border: none;
}

body .mainContainer .p-dialog .p-dialog-header {
    border-bottom: 1px solid #CAA462;
    background: #000410;
    color: rgba(255, 255, 255, 0.87);
    padding: 1rem;
    border-radius: 0px;
}

body .mainContainer .p-dialog .p-dialog-content, body .mainContainer .p-dialog .p-dialog-footer {
    background: rgb(13, 17, 28);
    border: none;
}

body .mainContainer .p-dialog .p-message {
    margin: 0px;
    border-radius: 0px;
}

@media only screen and (max-width: 768px) {
    body .mainContainer .p-datatable .p-datatable-thead>tr>th, body .mainContainer .p-datatable .p-datatable-scrollable-header {
        border-bottom: 1px solid #CAA462;
    }
    body .mainContainer .p-dialog.p-component {
        width: 90vw;
    }
    body .mainContainer .p-toast.p-component.p-toast-top-right {
        top: 0px;
        right: 0px;
        width: calc(100vw - 16px);
        margin: 8px;
    }
}